@import '../../assets/style/variables';

.header-thumb {
	position: relative;
	display: flex;
	// justify-content: center;
	// align-items: center;
	// flex: 1;
	width: 100%;
	height: 50vh;
	max-height: 480px;
	background-color: navy;
	transition: opacity $duration ease-out, filter $duration ease-out, width $duration ease-in-out;
	border: 1px $link-alpha solid;
	background-position: center;
	background-size: cover;
	overflow: hidden;
	z-index: 1;

	&--link {
		cursor: pointer;
	}

	.info {
		// background-color: $link;
		margin: auto;
		padding: 20px;
		white-space: nowrap;
		opacity: 0;
		transition: opacity $duration ease-out, filter $duration ease-out, width $duration ease-in-out;
		color: white;
		border-radius: 5px;
		font-family: FontMain;
		font-weight: bold;
		letter-spacing: 0.2em;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
		border: 2px solid white;

		&:hover {
			background-color: $link-hover !important;
			transform: scale(1.2);
		}
	}

	filter: grayscale(0.25) !important;

	&:hover {
		width: 90% !important;
		opacity: 1 !important;
		filter: grayscale(0) !important;
		z-index: 10;
		// transform: translate3d(0, 0px, 100px);
		box-shadow: 0px 0px 20px #000000;
		.info {
			opacity: 1;
		}
	}
}

.non-selected {
	// opacity: 0.1;
	filter: grayscale(50%);
}

@media only screen and (max-width: 420px) {
	.header-thumb {
		.info {
			opacity: 1;
		}
	}
}
