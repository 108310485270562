@import '../../assets/style/variables';

.case-info {
	margin-top: 50px;
	.case-expand {
		// position: relative;
		// display: flex;
		display: inline-flex;
		// flex: 1;
		// flex-direction: row;
		// background-color: black;
		// border-bottom: 2px solid black;
		// color: white;
		align-items: center;
		justify-content: space-between;

		width: 80vw;
		max-width: 1024px;
	}

	.expand-but {
		cursor: pointer;
		text-decoration: none;
	}

	.case-info-folder {
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		// background-color: pink;
		width: 80vw;
		max-width: 1024px;
		height: 0px;
		overflow: hidden;
	}

	* {
		border: 0;
	}

	iframe,
	img,
	video {
		background-color: black;
		outline: 1px solid lightgray;
	}

	.first-line {
		height: 1px;
		width: $line-width;
		background-color: gray;
		margin-bottom: 4vh;
	}
	.mini-line {
		height: 1px;
		width: $line-width;
		background-color: lightgray;
	}
}
