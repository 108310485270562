.icon-lockup {
	// background: red;
	height: 50%;
	max-height: 40px;
	white-space: nowrap;
	display: flex;
	position: relative;
	vertical-align: middle;
	align-items: center;
	justify-content: left;
	width: fit-content;
	// width: 400px;
	margin: 1vw 1vw 1vw 0;
	// padding-right: 2vw;
	padding: 0.5vw 0.5vw;
	border-radius: 2px;
	cursor: pointer;
	// background-color: red;

	svg {
		height: 100%;
		max-width: 50px;
	}
}
