@import '../../assets/style/variables';

.banner {
	&--link {
		cursor: pointer;
		// background-color: $link-alpha;

		transition: transform $duration ease-out;

		&:hover {
			transform: scale(1.05);
		}
	}
}
