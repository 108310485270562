$padding: 60px;
$duration: 250ms;

$link: #0073ff;
$link-red: #ff7300;
$link-green: #00cf29;
$link-alpha: rgba(0, 115, 255, 0.5);
$link-hover: #00aaff;
$medium-gray: #383940;
$line-width: 90%;

@font-face {
	font-family: 'FontMain';
	src: url('../fonts/Oswald-Light.woff') format('woff');
}
@font-face {
	font-family: 'Rubik';
	src: url('../fonts/rubik-light.woff') format('woff');
}
