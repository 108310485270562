@import '../../assets/style/variables';

.case-container {
	background-color: rgba(255, 255, 255, 0.2);
	background-color: rgba(255, 255, 255, 0.75);
	width: 100%;
	// background-image: url('../../assets/images/case_bg_tile.jpg');
	overflow: hidden;
	color: black;
	position: relative;
	z-index: 1;
	// height: 0px;
	height: auto;
	display: flex;
	font-family: Rubik;
	letter-spacing: 0em;
	// padding-top: 50px;
	// margin-top: 50px;
	// opacity: 0;

	.case-shadow {
		width: 100%;
		height: 10px;
		position: absolute;
	}
	$shadowStart: rgba(0, 0, 0, 0.5);
	$shadowEnd: rgba(0, 0, 0, 0);
	// .case-shadow-top {
	// 	// top: -150px;
	// 	background: linear-gradient($shadowStart, $shadowEnd);
	// }
	.case-shadow-bottom {
		bottom: 0px;
		background: linear-gradient($shadowEnd, $shadowStart);
	}

	.case-study {
		width: 80vw;
		max-width: 1024px;
		// max-height: 100vh;
		z-index: 2;
		position: relative;
		text-align: left;

		// background-color: rgba(0, 0, 0, 0.1);
		padding: 20px 0px;
		margin: 20px auto;
		border-radius: 10px;

		// p {
		// 	margin-left: 4vw;
		// }
		h2 {
			font-size: 1.5em;
			margin-left: 0;
			padding: 20px 0;
			// border-top: 1px solid gray;
			border-bottom: 1px solid black;
			text-align: left;

			.sub-copy {
				word-break: initial;
				font-size: 0.6em;
				letter-spacing: 0.25em;

				span {
					word-break: break-all;
					opacity: 0.25;
					margin: 0 0.5em;
				}
			}
		}
		h3 {
			white-space: nowrap;
			// font-style: italic;
			// font-size: 0.5em;
			font-weight: bolder;
			// margin-bottom: 10px;
		}

		.sub-title {
			font-size: 110%;
			color: blue;
		}

		.description {
			margin-top: 20px;
			// margin-bottom: 20px;
		}

		.study-icon-lockup {
			// background-color: lime;
			width: calc(100% - 8%);
			max-height: 200px;
			display: flex;
			flex-wrap: wrap;
			justify-content: baseline;
			// margin-left: 4vw;
			margin-bottom: 10px;
			p {
				margin-left: 0.5vw;
			}
			// vertical-align: middle;
			// align-items: center;
			// justify-content: left;
			// transition: 250ms !important;

			// &:hover {
			// 	// opacity: 0.5;
			// 	color: $link-hover;
			// }

			a {
				// transition: 250ms !important;
				color: unset;
				text-decoration: none;
			}

			.nav-on {
				background-color: black;
				color: white;
				pointer-events: none;
				border-radius: 2px;
			}
		}
	}
}
