@import '../../assets/style/variables';

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

body {
	background-color: white;

	background: linear-gradient(325deg, #000000, #ffffff 100%);
}

a {
	color: $link;

	font-weight: bold;

	&:hover {
		color: $link-hover;
		text-decoration: none;
	}
}

.App {
	text-align: center;
	position: relative;
	font-size: 1em;
	line-height: 2em;

	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}
h1 {
	letter-spacing: 0em;
	white-space: nowrap;
	font-weight: 100;
	font-size: 2em;
	span {
		font-size: 150%;
	}
}
h2 {
	white-space: nowrap;

	font-size: 0.7em;
	margin-left: 20px;
}

.red {
	color: darkred;
	font-weight: 600;
}

.site-header {
	text-align: left;
	width: 100%;
	position: fixed;
	color: white;
	z-index: 10;
	background-color: $link;
	line-height: 3em;

	padding: 0 120px;

	background: linear-gradient(270deg, $link-hover, $link);

	* {
		display: inline;
	}

	a {
		color: white;

		&:hover {
			color: lightgray;
		}
	}
}
.header {
	justify-content: center;
	align-items: center;
	display: flex;

	* {
		display: inline;
	}
}

.page-item {
	position: relative;
	z-index: 2;
	font-family: FontMain;
	letter-spacing: 0.5em;
}

.page-intro:nth-of-type(2n - 1) {
	// background-color: rgba(0, 0, 0, 0.1);
	background-color: white;
}
.page-intro:nth-of-type(2n) {
	background-color: rgba(255, 255, 255, 0.2);
	// background-color: white;
}

.page-intro {
	width: 100%;
	font-family: Rubik;
	// background-color: red;

	padding: 20px 20px;
	display: flex;

	.intro-content {
		max-width: 1024px;
		margin: auto auto;
	}
	p {
		width: 100%;
		text-align: left;
	}

	img {
		width: 100%;
		max-height: 480px;
		height: auto;
		object-fit: contain;
	}

	h2 {
		font-size: 1.5em;
		margin-left: 0;
		padding: 20px 0;
		// border-top: 1px solid gray;
		border-bottom: 1px solid black;
		text-align: left;

		.sub-copy {
			word-break: initial;
			font-size: 0.6em;
			letter-spacing: 0.25em;

			span {
				word-break: break-all;
				opacity: 0.25;
				margin: 0 0.5em;
			}
		}
	}
}

.bg {
	box-shadow: 0px 0px 20px #000000;
	z-index: 10;
}

.line-wide {
	width: 90%;
	height: 1px;
	background-color: black;
	margin: 10px;
}
.line-div {
	color: lightgray;
}
.logo-top {
	color: black;
	width: 80vw;
	max-width: 1024px;
	margin: 75px auto auto auto;

	font-size: 0.75em;
	p {
		letter-spacing: 0.2em;
	}

	h1 {
		font-size: 1.5em;
	}

	.line-div {
		opacity: 0.5;
	}

	.logos {
		justify-content: center;
		align-items: center;

		img {
			height: 60px;
			margin: 10px 20px;
			padding: 10px;
			border-radius: 5px;
			object-fit: contain;
		}

		.wide {
			width: 100px;
		}
		.wider {
			width: 150px;
		}

		.tall {
			width: 50px;
		}
	}
}
.bottom {
	color: white;
	max-width: 80%;
	width: 100%;
	margin: auto;
	padding-bottom: 50px;

	font-size: 0.8em;
	h1 {
		font-size: 1.5em;
		margin-bottom: 2px;
	}
	p {
		letter-spacing: 0.2em;
	}

	.line-div {
		opacity: 0.5;
	}

	.logos {
		justify-content: center;
		align-items: center;

		img {
			height: 60px;

			margin: 10px 20px;
			padding: 10px;
			border-radius: 5px;
			object-fit: contain;
		}

		.wide {
			width: 100px;
		}
		.wider {
			width: 150px;
		}

		.tall {
			width: 50px;
		}
	}
}

.hightlights {
	position: relative;
	display: flex;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
	background-color: $medium-gray;
	width: 100%;
	// overflow: hidden;

	&:hover {
		.header-thumb {
			width: 15%;
			opacity: 0.5;
			border: 1px $medium-gray solid;
			filter: grayscale(100%);
		}
	}
}

@media only screen and (max-width: 900px) {
	.site-header {
		padding: 0 20px;
	}

	.page-intro {
		.intro-content {
			max-width: 720px;
		}
	}
}

@media only screen and (max-width: 800px) {
	.site-header {
		display: flex;
		flex-direction: column;
		padding: 0 20px 20px 20px;

		h2 {
			margin-left: 0;
		}
	}

	.logo-top {
		margin-top: 100px;
	}

	.page-intro {
		font-size: 0.9em;

		.intro-content {
			max-width: 620px;
		}
	}

	h2 {
		white-space: normal;
		word-break: break-word;
	}
}
@media only screen and (max-width: 420px) {
	.page-intro {
		font-size: 0.9em;

		.intro-content {
			max-width: 350px;
		}
		h2 {
			font-size: unset;
		}
	}

	.hightlights {
		display: unset;
	}
}
